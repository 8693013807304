/* eslint-disable import/no-unresolved */
import { TDeliveryItem } from './types'

const deliverItemState: TDeliveryItem = {
  currentIndex: 0,
  listAgent: [],
  listScheduleBus: [],
  scheduleId: 0,
  toAgentId: 0,
  toAgentName: '',
  addressToAgent: '',
  toCityId: null,
  deliveryHdId: 0,
  deliveryIsCompany: false,
  typeDelivery: '',
  listCategoryItems: [],
  listItems: [],
  companyId: 0,
  customerId: 0,
  adressCompanyId: 0,
  receiveAddressCompanyId: 0,
  retailId: 0,
  tabsSchedule: {
    key: 0,
    params: 'HariIni'
  },
  dataReceiver: {
    nameReceiver: null,
    addressReceiver: null,
    telpReceiver: null,
    notesReceiver: null
  },
  dataSender: {
    nameSender: '',
    addressSender: '',
    telpSender: '',
    notesSender: '',
    pickupScheduleId: 0,
    usernameSender: '',
    username: ''
  },
  dataAfterPost: null,
  lastReceipt: null,
  positionTabs: 1,
  voucherId: null,
  paymentId: null,
  paymentRef: null,
  listDiskon: [],
  grandPrice: 0,
  diskonPelanggan: 0,
  biayaTambahanResi: 0,
  priceRecognition: 0,
  costInsurance: 0,
  diskonPelangganCalculate: 0,
  itemsLabel: [],
  descriptionAddOn: null,
  itemsSticker: [],
  additionalFeeDesc: null,
  voucherAttr: {
    voucherCodeId: null,
    voucherHdId: null,
    kodeVoucher: null,
    voucherValue: 0
  },
  isMiisi: false,
  isDraft: false
}

export default deliverItemState
