import { Remote, remoteEnum } from '@/data/source/remote/remoteVoucherV2'

const baseUrl = 'msvoucherhds'

const addVoucherHdV2 = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editVoucherHdV2 = (id: number, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
// const deleteVoucherHd = (id: number) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const fetchVoucherHdV2 = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchVoucherHdV2Details = (id: any) => new Remote(remoteEnum.get, `${baseUrl}/${id}/details`)
const fetchVoucherHdV2Codes = (id: any) => new Remote(remoteEnum.get, `${baseUrl}/${id}/codes`)
// const fetchOneVoucherHd = (id: number, filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}${filter}`)
// const changeIsActiveVoucherHd = (id: number, data: any) => new Remote(remoteEnum.patch, `${baseUrl}/${id}`, data)
// const uploadFileVoucherHd = (data: any) => new Remote(remoteEnum.post, 'UploadImg', data)

export {
  fetchVoucherHdV2,
  addVoucherHdV2,
  editVoucherHdV2,
  fetchVoucherHdV2Details,
  fetchVoucherHdV2Codes
  // changeIsActiveVoucherHd,
  // uploadFileVoucherHd,
  // fetchOneVoucherHd,
  // deleteVoucherHd
}
