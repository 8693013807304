import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission, checkPermissionObj } from '@/utils/helpers/checkPermission'

export default (val = '') => {
  const listRoute = []
  const permission = {
    // read: checkPermission(PermissionEnum.GetAgentType),
    // write: checkPermission(PermissionEnum.CreateAgentType),
    // update: checkPermission(PermissionEnum.UpdateAgentType),
    // delete: checkPermission(PermissionEnum.DeleteAgentType)
    read: true,
    write: true,
    update: true,
    delete: true
  }
  // if (checkPermissionObj(permission)) {
  listRoute.push({
    path: `${val}/tipe-agent`,
    name: 'master-tipe-agent',
    component: () => import('@/views/master/trips/type-agent/TypeAgent.vue'),
    meta: {
      group: 'KOTA & AGEN',
      permission
    }
  })
  // }
  return listRoute
}
