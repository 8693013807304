/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllHarga, changeIsActiveHarga, addHarga, editHarga, deleteHarga, fetchOneHarga, fetchAllHargaDetail,
  editAltMultipleHargaDetail, addDuplicateHargaKategori, deleteHargaKategori, addDuplicateHarga, fetchPencarianHarga,
  cloneHarga, addHargaEvent
} from '@/data/source/remote/api/master'
import {
  fetchAllPrices, fetchPriceGoodCategory, addPrice, editPrice, fetchSinglePrice, fetchSinglePriceChilds,
  fetchShippingRates, duplicatePriceGoodCategory, modifyPriceGoodCategory
} from '@/data/source/remote/api'
import { Observable } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

class PriceUsecase implements ICrud {
  async getAll(filter: any = ''): Promise<Response> {
    let params = ''
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }
      const arrayFilter = []
      if (filter.search) {
        arrayFilter.push(`Contains(tolower(Kode), '${filter.search}')`)
      }
      if (filter.custom) {
        arrayFilter.push(filter.custom)
      }
      if (arrayFilter.length > 0) {
        params = `${params}&$filter=${arrayFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderBy= ${filter.sorting}` : params
    }
    const response = await new Repository(fetchAllHarga(`?&$count=true${params}`), null).getResult(false)
    return response
  }

  getAllPrice(filter: any) {
    console.log(filter)
    let params = '?'
    if (filter) {
      const arrayFilter = []
      if (filter.search) {
        arrayFilter.push(`code=${filter.search}`)
      }
      if (filter.orderby) {
        arrayFilter.push(`orderby=${filter.orderby}`)
      }
      if (filter.pageNumber) {
        arrayFilter.push(`PageNumber=${filter.pageNumber}`)
      }
      if (filter.pageSize) {
        arrayFilter.push(`PageSize=${filter.pageSize}`)
      }
      if (arrayFilter.length > 0) {
        params = `${params}${arrayFilter.join('&')}`
      }
    }
    return new Repository(fetchAllPrices(params), null).getResult(false)
  }

  async getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  async getDataDetail(id: any) {
    return new Repository(fetchOneHarga(id, '?$expand=MsHargaKategoris($select=KategoriId;$expand=kategori($select=nama;$expand=Satuan($select=Nama,Id,Kode)))'), null).getResult(false)
  }

  getDataHargaDetail(filter: any = {}) {
    const maxMinFilter = (filterData : any) => {
      if (filterData.maxMin !== undefined) {
        return ` and  Max ge ${filterData.maxMin} and Min le ${filterData.maxMin}`
      }
      return ''
    }
    const params = (filter) ? `?$orderBy=Min asc &$filter=HargaHdId eq ${filter.hargaHdId} and KategoriId eq ${filter.kategoriId} ${maxMinFilter(filter)}` : ''
    // params = params + (filter.kategoriId) ? ` and KategoriId eq ${filter.kategoriId}` : ''
    return new Repository(fetchAllHargaDetail(params), null).getResult(false)
  }

  getDataPriceDetail(filter: any = {}) {
    console.log(filter)
    let params = '?'
    if (filter) {
      const arrayFilter = []
      if (filter.priceType) {
        arrayFilter.push(`priceType=${filter.priceType}`)
      }
      if (filter.origCityId) {
        arrayFilter.push(`origCityId=${filter.origCityId}`)
      }
      if (filter.destCityId) {
        arrayFilter.push(`destCityId=${filter.destCityId}`)
      }
      if (filter.goodCategoryId) {
        arrayFilter.push(`goodCategoryId=${filter.goodCategoryId}`)
      }
      if (filter.value) {
        arrayFilter.push(`value=${filter.value}`)
      }
      if (arrayFilter.length > 0) {
        params = `${params}${arrayFilter.join('&')}`
      }
    }
    return new Repository(fetchShippingRates(params), null).getResult(false)
  }

  getSinglePrice(id: any) {
    return new Repository(fetchSinglePrice(id), null).getResult(false)
  }

  getSinglePriceChilds(id: number) {
    return new Repository(fetchSinglePriceChilds(id), null).getResult(false)
  }

  getPriceDetails(id: number) {
    return new Repository(fetchPriceGoodCategory(id), null).getResult(false)
  }

  /* eslint no-else-return: ["error", {allowElseIf: true}] */
  submitData({
    onCopy,
    id,
    onClone,
    fromId
  }: any, data: any): Promise<Response> {
    if ((id !== '' && onCopy) || onClone) {
      data = {
        FromId: id,
        Harga: {
          Kode: data.Code,
          Version: data.Version,
          TanggalBerlaku: data.EffectiveDate,
          IsActive: data.IsActive
        }
      }
      if (onCopy) {
        return new Repository(addDuplicateHarga(data), null).getResult(false)
      } else if (onClone) {
        return new Repository(cloneHarga(data), null).getResult(false)
      }
    } else if (fromId) {
      data = {
        AsalId: Number(fromId),
        Kode: data.Code,
        Version: data.Version,
        TanggalBerlaku: data.EffectiveDate,
        TanggalBerakhir: data.EndDate
      }
      return new Repository(addHargaEvent(data), null).getResult(false)
    } else if (id !== '') {
      data.Id = id
      delete data.EndDate
      return new Repository(editPrice(id, data), null).getResult(false)
    }
    delete data.EndDate
    return new Repository(addPrice(data), null).getResult(false)
  }

  submitDataExcelMultiple(id: number, data: any): Promise<Response> {
    return new Repository(modifyPriceGoodCategory(id, data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteHarga(id), null).getResult(false)
  }

  changeIsActive(data: any): Promise<Response> {
    return new Repository(changeIsActiveHarga(data), null).getResult(false)
  }

  duplicateTypeItems(id: any, data: any): Promise<Response> {
    return new Repository(duplicatePriceGoodCategory(id, data), null).getResult(false)
  }

  deleteTypeItems(data: any): Promise<Response> {
    return new Repository(deleteHargaKategori(data), null).getResult(false)
  }

  submitPencarianHarga(data: any): Promise<Response> {
    return new Repository(fetchPencarianHarga(data), null).getResult(false)
  }

  searchInput(input: string, filter: any = '') {
    const search = Observable.create((observer: any) => {
      observer.next(input)
    })
    search.pipe(
      debounceTime(500)
    ).subscribe((val: any) => {
      console.log('input', val)
    })
  }
}

const priceUseCase = new PriceUsecase()

export {
  priceUseCase
}
