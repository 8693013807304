/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import {
  fetchDashboardPendapatan, fetchLabelStock, fetchStickerStock, fetchSummary
} from '@/data/source/remote/api'
// eslint-disable-next-line import/no-unresolved
import { IResultFetchSummary, IResultLabelStock, IResultStickerStock } from '@/data/source/remote/api/backend/V2/dashboard-model'

class DashboardUseCase {
  getDashboardSummary(): Promise<Response<IResultFetchSummary>> {
    return new Repository<IResultFetchSummary>(fetchSummary(), null).getResult(false)
  }

  getLabelStock(): Promise<Response<IResultLabelStock>> {
    return new Repository<IResultLabelStock>(fetchLabelStock(), null).getResult(false)
  }

  getSticketStock(): Promise<Response<IResultStickerStock>> {
    return new Repository<IResultStickerStock>(fetchStickerStock(), null).getResult(false)
  }

  dashboardPendapatan(id: any): Promise<Response> {
    const response = new Repository(fetchDashboardPendapatan(id), null).getResult(false)
    return response
  }
}

const dashboardUseCase = new DashboardUseCase()

export {
  dashboardUseCase
}
