import type {
  MutationTree
} from 'vuex'
import {
  TDeliveryItem
} from './types'

const mutations: MutationTree<TDeliveryItem> = {
  SET_CURRENT_INDEX: (state, payload: any) => {
    state.currentIndex = payload
  },
  SET_DEFAULT_STATE: (state) => {
    state.currentIndex = 0
    // state.listAgent = []
    state.listScheduleBus = []
    state.scheduleId = 0
    state.toAgentId = 0
    state.toAgentName = ''
    state.addressToAgent = ''
    state.toCityId = null
    state.deliveryHdId = 0
    state.deliveryIsCompany = false
    state.typeDelivery = ''
    state.listCategoryItems = []
    state.listItems = []
    state.companyId = 0
    state.adressCompanyId = 0
    state.receiveAddressCompanyId = 0
    state.retailId = 0
    state.customerId = 0
    state.tabsSchedule = {
      key: 0,
      params: 'HariIni'
    }
    state.dataReceiver = {
      nameReceiver: null,
      addressReceiver: null,
      telpReceiver: null,
      notesReceiver: null
    }
    state.dataSender = {
      nameSender: '',
      addressSender: '',
      telpSender: '',
      pickupScheduleId: 0,
      username: '',
      notesSender: '',
      usernameSender: ''
    }
    state.dataAfterPost = null
    state.positionTabs = 1
    state.voucherId = null
    state.paymentId = null
    state.paymentRef = null
    state.listDiskon = []
    state.grandPrice = 0
    state.diskonPelanggan = 0
    state.biayaTambahanResi = 0
    state.priceRecognition = 0
    state.costInsurance = 0
    state.diskonPelangganCalculate = 0
    state.itemsLabel = []
    state.descriptionAddOn = null
    state.itemsSticker = []
    state.additionalFeeDesc = null
    state.isMiisi = false
  },
  SET_LIST_AGENT: (state, payload: any) => {
    state.listAgent = payload
  },
  SET_LIST_AGENT_DEFAULT: (state) => {
    state.listAgent = []
  },
  SET_LIST_SCHEDULE_BUS: (state, payload: any) => {
    state.listScheduleBus = payload
  },
  SET_TO_AGENT_ID: (state, payload: any) => {
    state.toAgentId = payload
  },
  SET_TO_AGENT_NAME: (state, payload: any) => {
    state.toAgentName = payload
  },
  SET_ADDRESS_TO_AGENT: (state, payload: any) => {
    state.addressToAgent = payload
  },
  SET_SCHEDULE_ID: (state, payload: any) => {
    state.scheduleId = payload
  },
  SET_DELIVERY_HD_ID: (state, payload: any) => {
    state.deliveryHdId = payload
  },
  SET_DELIVERY_ID_COMPANY: (state, payload: any) => {
    state.deliveryIsCompany = payload
  },
  SET_TYPE_DELIVERY: (state, payload: any) => {
    state.typeDelivery = payload
  },
  SET_LIST_CATEGORY_ITEMS: (state, payload: any) => {
    state.listCategoryItems = payload
  },
  SET_LIST_ITEMS: (state, payload: any) => {
    state.listItems = payload
  },
  PUSH_LIST_ITEMS: (state, item: any) => {
    state.listItems.push(item)
  },
  SET_COMPANY_ID: (state, payload: any) => {
    state.companyId = payload
  },
  SET_CUSTOMER_ID: (state, payload: any) => {
    state.customerId = payload
  },
  SET_COMPANY_ADDRESS_ID: (state, payload: any) => {
    state.adressCompanyId = payload
  },
  SET_COMPANY_RECEIVE_ADDRESS_ID: (state, payload: any) => {
    state.receiveAddressCompanyId = payload
  },
  SET_DATA_RECEIVER: (state, payload: any) => {
    state.dataReceiver.nameReceiver = payload.namaPenerima
    state.dataReceiver.addressReceiver = payload.alamatPenerima
    state.dataReceiver.telpReceiver = payload.telpPenerima
    state.dataReceiver.notesReceiver = payload.notesPenerima
  },
  SET_DATA_SENDER: (state, payload: any) => {
    // console.log('payload mutation', payload)
    state.dataSender.nameSender = payload.namaPengirim
    state.dataSender.addressSender = payload.alamatPengirim
    state.dataSender.telpSender = payload.telpPengirim
    state.dataSender.notesSender = payload.notesPengirim
    state.dataSender.pickupScheduleId = payload.pickupScheduleId
    state.dataSender.usernameSender = payload.usernamePengirim
  },
  SET_DATA_AFTER_POST: (state, payload: any) => {
    state.dataAfterPost = payload
  },
  SET_DATA_LAST_RECEIPT: (state, payload: any) => {
    state.lastReceipt = payload
  },
  CHANGE_ITEM_LIST_ITEMS: (state, payload: any) => {
    for (let i = 0; i < state.listItems.length; i++) {
      if (state.listItems[i].Id === payload.Id) {
        state.listItems[i] = payload
        break
      }
    }
  },
  SET_POSTION_TABS: (state, payload: any) => {
    state.positionTabs = payload
  },
  SET_DEFAULT_FORM_STATE: (state) => {
    state.companyId = 0
    state.adressCompanyId = 0
    state.receiveAddressCompanyId = 0
    state.retailId = 0
    state.customerId = 0
    state.dataReceiver = {
      nameReceiver: null,
      addressReceiver: null,
      telpReceiver: null,
      notesReceiver: null
    }
    state.dataSender = {
      nameSender: '',
      addressSender: '',
      telpSender: '',
      notesSender: '',
      pickupScheduleId: 0,
      username: '',
      usernameSender: ''
    }
  },
  CHANGE_ITEM_LIST_ITEMS_FROM_INDEX: (state, payload: any) => {
    state.listItems[payload.index] = payload.item
  },
  DELETE_ITEM_LIST_ITEMS: (state, id: any) => {
    state.listItems = state.listItems.filter((item: any) => item.Id !== id)
  },
  SET_VOUCHER_ID: (state, voucherId: any) => {
    state.voucherId = voucherId
  },
  SET_ATTRIBUTE_VOUCHER: (state, attr: any) => {
    state.voucherAttr = {
      voucherCodeId: attr.VoucherCodeId,
      voucherHdId: attr.VoucherHdId,
      kodeVoucher: attr.VoucherKode,
      voucherValue: attr.VoucherValue
    }
  },
  SET_DEFAULT_ATTRIBUTE_VOUCHER: (state) => {
    state.voucherAttr = {
      voucherCodeId: null,
      voucherHdId: null,
      kodeVoucher: null,
      voucherValue: 0
    }
  },
  SET_PAYMENT_ID: (state, paymentId: any) => {
    state.paymentId = paymentId
  },
  SET_PAYMENT_REF: (state, paymentRef: any) => {
    state.paymentRef = paymentRef
  },
  SET_LIST_DISKON: (state, listDiskon: any) => {
    state.listDiskon = listDiskon
  },
  SET_GRAND_PRICE: (state, grandPrice: any) => {
    state.grandPrice = grandPrice
  },
  SET_BIAYA_TAMBAHAN: (state, biayaTambahan: any) => {
    state.biayaTambahanResi = biayaTambahan
  },
  SET_PRICE_RECOGNITION: (state, priceRecognition: any) => {
    state.priceRecognition = priceRecognition
  },
  SET_COST_INSURANCE: (state, costInsurance: any) => {
    state.costInsurance = costInsurance
  },
  SET_DISKON_PELANGGAN: (state, biaya: any) => {
    // console.log('biaya', biaya)
    state.diskonPelanggan = 0
    state.diskonPelanggan = biaya
    // console.log('diskonPelanggan', state)
  },
  CALCULATE_GRAND_PRICE: (state) => {
    // console.log('state mutation', state)
    let total = 0
    state.listItems.forEach((item: any) => {
      // console.log('item', item)
      const subTotal = item.TotalHarga - (item.DiskonValue ? item.DiskonValue : 0)
      if (subTotal < 0) {
        total += 0
      } else {
        total += subTotal
      }
    })
    // console.log('total', total)
    // total -= state.voucherAttr.voucherValue
    total += state.biayaTambahanResi
    total += state.costInsurance
    total -= state.diskonPelangganCalculate

    state.grandPrice = total
    // console.log('total', total)
  },
  CALCULATE_TOTAL_DISKON: (state) => {
    // console.log('anuy')
    // console.log('state mutation', state)
    // if (state.customerId !== null) {
    let total = 0
    let data = 0
    state.listItems.forEach((item: any) => {
      const subTotal = item.Harga
      if (subTotal < 0) {
        total += 0
      } else {
        total += subTotal
      }
    })
    if (state.diskonPelanggan > 0 && state.diskonPelanggan !== null && state.customerId !== 0) {
      // console.log('boo')
      /* eslint-disable-next-line no-mixed-operators */
      data = (state.diskonPelanggan / 100) * total
      // console.log('data', data)
      state.diskonPelanggan = data
    } else if (state.companyId !== null) {
      // console.log('auu')
      data = state.diskonPelanggan
    } else {
      // console.log('baa')
      data = 0
    }
    // state.diskonPelanggan = data
    // data = state.diskonPelanggan
    state.diskonPelangganCalculate = Math.round(data)
    // console.log('state mutation after calculate', state)
    // }
  },
  SET_ITEMS_LABEL: (state, labels: any) => {
    state.itemsLabel = labels
  },
  SET_ITEMS_STICKER: (state, payload: any) => {
    state.itemsSticker = payload
  },
  PUSH_ITEMS_STICKER: (state, item: any) => {
    state.itemsSticker.push(item)
  },
  SET_KEY_TABS_SCHEDULE: (state, payload: any) => {
    state.tabsSchedule.key = payload
  },
  SET_PARAMS_TABS_SCHEDULE: (state, payload: any) => {
    state.tabsSchedule.params = payload
  },
  SET_DESCRIPTION_ADDON: (state, payload: any) => {
    state.descriptionAddOn = payload
  },
  SET_TO_CITY_ID: (state, payload: any) => {
    state.toCityId = payload
  },
  SET_DESCRIPTION_ADDITIONALFEE: (state, payload: any) => {
    state.additionalFeeDesc = payload
  },
  SET_CUSTOMER_USERNAME: (state, payload: any) => {
    state.additionalFeeDesc = payload
  },
  SET_ISMIISI: (state, payload: boolean) => {
    state.isMiisi = payload
  },
  SET_ISDRAFT: (state, payload: boolean) => {
    state.isDraft = payload
  },
}

export default mutations
