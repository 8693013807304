import claim from './claim'
import insurance from './insurance'
import claimAndPaymentAg from './claimAndPaymentAg'

export default () => {
  const getClaim = claim('barang')
  const getInsurance = insurance('barang')
  const getClaimAndInsuranceAg = claimAndPaymentAg('barang')

  return [
    ...getClaim,
    ...getInsurance,
    ...getClaimAndInsuranceAg
  ]
}
