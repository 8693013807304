import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission } from '@/utils/helpers/checkPermission'

export default (val = '') => [
  {
    path: `${val}/pembayaran`,
    name: 'master-pembayaran',
    component: () => import('@/views/master/transactions/payment/Payment.vue'),
    meta: {
      group: 'TRANSAKSI',
      permission: {
        // read: checkPermission(PermissionEnum.ListPaymentTypes),
        // write: checkPermission(PermissionEnum.CreatePaymentType),
        // update: checkPermission(PermissionEnum.UpdatePaymentType),
        // delete: checkPermission(PermissionEnum.DeletePaymentType)
        read: true,
        write: true,
        update: true,
        delete: true
      }
    }
  }
]
