/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import ICrud from '@/domain/usecase/iCrud'
import {
  scanResi,
  fetchAllPengecekanResi,
  closeJadwal,
  pengecekanResiCheckResi,
  checkTransPengirimanHdScans
} from '@/data/source/remote/api'
import Repository from '@/data/repository'

class ReceiptCheckingUseCase implements ICrud {
  getAll(filter: any = ''): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getOne(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  sendScanResi(data: any): Promise<Response> {
    return new Repository(checkTransPengirimanHdScans(data), null).getResult(false)
  }

  getPengecekanResi(filter: any): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }

      const arrayFilter = []
      if (filter.search) {
        arrayFilter.push(`(Contains(tolower(Kode), '${filter.search}'))`)
      }
      if (filter.custom) {
        arrayFilter.push(filter.custom)
      }
      if (filter.filter) {
        arrayFilter.push(filter.filter)
      }
      if (arrayFilter.length > 0) {
        params = `${params}&$filter=${arrayFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.orderBy) ? `${params}&$orderBy=${filter.orderBy}` : params
    }
    return new Repository(fetchAllPengecekanResi(params), null).getResult(false)
  }

  closingSchedule(id: number, data: any): Promise<Response> {
    return new Repository(closeJadwal(id, data), null).getResult(false)
  }

  editPengecekanResiCheckResi(data: any): Promise<Response> {
    return new Repository(pengecekanResiCheckResi(data), null).getResult(false)
  }
}

const receiptCheckingUseCase = new ReceiptCheckingUseCase()

export {
  receiptCheckingUseCase
}