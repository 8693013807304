/* eslint-disable class-methods-use-this */
// import networkBoundResource from '@/data/networkBoundResource'
import store from '@/store'
import { Response } from '@/domain/entities'

class Repository<TResult = any> {
  remote: any

  sourceDb: any

  savingData: any

  constructor(remote: any, sourceDb: any, savingData: any = null) {
    this.remote = remote
    this.sourceDb = sourceDb
    if (savingData) {
      this.savingData = savingData
    }
  }

  message(e: any) {
    if (e.response.data.Header && e.response.data.Detail) {
      return `${e.response.data.Header} ${e.response.data.Detail}`
    }
    if (e.response.data?.title || e.response.data?.detail) {
      return `${e.response.data?.title} ${e.response.data.detail}`
    }
    return ''
  }

  private result = async (haveLocal: boolean) => {
    const data = new Response<TResult>()
    if (store.state.network.connection) {
      try {
        const fetch = await this.fetchData().run()
        const code = fetch.status
        const result = fetch.data
        if (code === 201) {
          data.result = fetch.data
          data.message = fetch.data.detail ?? fetch.data.Detail
        } else if (code === 200) {
          // eslint-disable-next-line no-prototype-builtins
          if (typeof result.value === 'object' || Array.isArray(result.value)) {
            data.result = result.value
          } else {
            data.result = result
          }
          data.message = 'berhasil ditampilkan'
        } else if (code === 202 || code === 204) {
          return data
        } else {
          data.error = true
          data.message = `${fetch.data.title}, ${fetch.data.detail}`
          data.result = fetch.data.value
        }
        if ('@odata.count' in result) {
          data.count = result['@odata.count']
        }
        data.code = fetch.status
      } catch (e: any) {
        if (typeof e.response === 'undefined') {
          data.error = true
          data.message = 'A network error occurred. This could be a CORS issue or a dropped internet connection. It is not possible for us to know.'
          // return Promise.reject(e)
          return data
        }
        data.error = true
        data.code = e.response.status
        data.message = this.message(e)
        // if (e.response.status === 400) {
        //   data.error = true
        //   data.message = `${e.response.data.title}, ${e.response.data.detail}`
        //   data.code = (e.response) ? e.request.status : 400
        // } else if (e.response.status === 401) {
        //   data.error = true
        //   data.message = `${e.response.data.title}, ${e.response.data.detail}`
        //   data.code = (e.response) ? e.request.status : 401
        // } else if (e.response.status === 502) {
        //   data.error = true
        //   data.message = 'Cek koneksi internet anda'
        //   data.code = (e.response) ? e.request.status : 502
        // } else {
        //   data.error = true
        //   data.message = 'Gagal mengambil data'
        //   data.code = (e.response) ? e.request.status : 500
        // }
      }
      if (haveLocal) {
        this.saveResultData(data)
      }
    } else if (haveLocal) {
      data.result = await this.loadFromDB()
    } else {
      store.dispatch('setNoInternet', true)
    }

    return data
  }

  loadFromDB() {
    return this.sourceDb
  }

  fetchData() {
    return this.remote
  }

  saveResultData(data: any): void {
    this.sourceDb.changeData(data)
    this.loadFromDB().run()
    console.log('save result data', data)
  }

  getResult = async (haveLocal: boolean): Promise<Response<TResult>> => {
    const data = await this.result(haveLocal)
    return data
  }
}

export default Repository
