import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'

const baseUrl = '/backend/v2/cities'

const fetchAllCities = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneCity = (id: any) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addCity = (payload: any) => new Remote(remoteEnum.post, `${baseUrl}`, payload)
const editCity = (id: any, payload: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, payload)

export {
  fetchAllCities,
  fetchOneCity,
  addCity,
  editCity
}
