import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'

const baseUrl = '/backend/v2/reports'

const fetchAllReportList = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchReportAuths = (reportId: any) => new Remote(remoteEnum.get, `${baseUrl}/${reportId}/auths`)
const fetchGenerateReportParams = (reportId: any) => new Remote(remoteEnum.get, `${baseUrl}/${reportId}/params`)
const generateReportResult = (reportId: any, payload: any) => new Remote(remoteEnum.post, `${baseUrl}/${reportId}/generate`, payload)
const addReportAuth = (reportId: any, payload: any) => new Remote(remoteEnum.post, `${baseUrl}/${reportId}/auths`, payload)
const deleteReportAuth = (reportId: any, authId: any) => new Remote(remoteEnum.delete, `${baseUrl}/${reportId}/auths/${authId}`)

export {
  fetchAllReportList,
  fetchReportAuths,
  fetchGenerateReportParams,
  generateReportResult,
  addReportAuth,
  deleteReportAuth
}
