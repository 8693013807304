import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission } from '@/utils/helpers/checkPermission'

export default (val = '') => {
  const listRoute = []
  // if (checkPermission('*:*')) {
  listRoute.push({
    path: `${val}/master-status-check-resi`,
    name: 'master-status-check-resi',
    component: () => import('@/views/master/transactions/status-check-resi/MasterStatusResi.vue'),
    meta: {
      group: 'Status Pengecekan',
      permission: {
        // read: checkPermission(),
        // write: checkPermission(),
        // update: checkPermission(),
        // delete: checkPermission()
        read: true,
        write: true,
        update: true,
        delete: true
      }
    }
  })
  // }
  return listRoute
}
