// import { Remote, remoteEnum } from '@/data/source/remote/remoteCourier'
import { Remote, remoteEnum } from '@/data/source/remote/remoteUpload'

const baseUrl = 'media/upload/custompath'

const uploadCustomPath = (namePath: any = '', data: any = null) => new Remote(remoteEnum.post, `${baseUrl}?path=${namePath}`, data)

export {
  uploadCustomPath
}
